import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NotificationManager} from 'react-notifications';
import {Table, Badge, Button} from 'reactstrap';
import {withLayoutContextProvider} from 'services/LayoutContext';
import {withRouter} from 'react-router-dom';
import ApiErrorsHelper from 'helpers/ApiErrorsHelper';
import DeleteButton from 'sharedComponents/buttons/deleteButton/DeleteButton';
import EditButtonLink from 'sharedComponents/buttons/editButtonLink/EditButtonLink';
import PageDataLoader from 'sharedComponents/pageDataLoader/PageDataLoader';
import React from 'react';

import ArticlesApi from 'clinic/apis/articles.api';

import CreateArticleButton from './createArticleButton/CreateArticleButton';
import PreviewModal from '../previewModal/PreviewModal';

class ClinicArticlesTab extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    articles: null,
    previewModalOpened: false,
    previewArticle: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState(
      {isLoading: true, loadingError: false, articles: null},
      async () => {
        let nextState = {isLoading: false};
        let articles = await ArticlesApi.getAllSummaries().catch((e) => {
          nextState.loadingError = true;
          return null;
        });

        if (articles) {
          nextState.articles = _.orderBy(articles, 'title');
        }

        this.setState(nextState);
      },
    );
  };

  onCreateArticle = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    let createdArticle = await ArticlesApi.add(formData).catch((e) => {
      error = e;
      return null;
    });
    this.props.layoutContextProvider.hideLoader();

    if (error) {
      return Promise.reject(error);
    } else {
      this.props.history.push(`articles/${createdArticle.id}`);
      return Promise.resolve();
    }
  };

  preview = async (id) => {
    this.props.layoutContextProvider.showLoader();
    let article = await ArticlesApi.get(id).catch((e) => {
      NotificationManager.error('Une erreur est survenue', null);
      return null;
    });

    this.props.layoutContextProvider.hideLoader();
    if (article) {
      this.setState({
        previewModalOpened: true,
        previewArticle: article,
      });
    }
  };

  onModalToggle = () => {
    this.setState({
      previewModalOpened: false,
      previewArticle: null,
    });
  };

  onDelete = async (id) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await ArticlesApi.delete(id).catch((e) => {
      ApiErrorsHelper.manage(e);
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      this.loadData();
    }
  };

  render() {
    return (
      <PageDataLoader
        isLoading={this.state.isLoading}
        loadingError={this.state.loadingError}>
        {this.renderContent()}
      </PageDataLoader>
    );
  }

  renderContent = () => {
    if (!this.state.articles) return null;

    return (
      <div>
        <div className="text-end mb-2">
          <CreateArticleButton onSubmit={this.onCreateArticle} />
        </div>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Titre</th>
              <th className="width-auto"></th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.articles.map((article) => (
              <tr key={article.id}>
                <td>{article.title}</td>
                <td className="text-center">
                  <Badge color={article.published ? 'success' : 'secondary'}>
                    {article.published ? 'Publié' : 'Non publié'}
                  </Badge>
                </td>
                <td className="width-auto text-center">
                  {article.type === 'video' && (
                    <a
                      href={`https://www.youtube.com/watch?v=${article.videoId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Visualiser"
                      className="btn btn-primary btn-sm me-2">
                      <FontAwesomeIcon icon="play" />
                    </a>
                  )}

                  {article.type === 'text' && (
                    <Button
                      color="primary"
                      size="sm"
                      className="me-2"
                      title="Visualiser"
                      onClick={() => this.preview(article.id)}>
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  )}

                  <EditButtonLink to={`articles/${article.id}`} />

                  <DeleteButton
                    title={
                      <span>
                        Supprimer <i>{article.title}</i>
                      </span>
                    }
                    data={article.id}
                    onConfirm={this.onDelete}
                    className="ms-1"
                    tooltip={`Supprimer ${article.title}`}>
                    <div>
                      Voulez-vous réellement supprimer l'article{' '}
                      <i>{article.title}</i> ?
                    </div>
                    {article.published && (
                      <div>
                        <FontAwesomeIcon icon="exclamation-triangle" />
                        &nbsp; Il ne sera plus lisible dans l'application ADEL.
                      </div>
                    )}
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PreviewModal
          article={this.state.previewArticle}
          isOpen={this.state.previewModalOpened}
          onToggle={this.onModalToggle}
        />
      </div>
    );
  };
}

export default withLayoutContextProvider(withRouter(ClinicArticlesTab));
