import {Redirect} from 'react-router-dom';
import ApiClient from 'clinic/services/ApiClient';
import Authentication from 'clinic/services/Authentication';
import ClinicChoice from 'clinic/components/clinicChoice/ClinicChoice';
import PrivateLayout from 'layouts/privateLayout/PrivateLayout';
import React from 'react';

export default class ClinicPrivateLayout extends React.PureComponent {
  editorialMenuItems = [
    {
      menuKey: 'myClinic',
      label: 'Mon établissement',
      icon: 'hospital',
      path: '/healthFacility',
    },
    {
      menuKey: 'operatingMethodsLinks',
      label: 'Table de correspondance',
      icon: 'table',
      path: '/operatingMethodsLinks',
    },
    // {
    //   menuKey: 'convocationPlacesLinks',
    //   label: 'Lieux de convocation',
    //   icon: 'map-marked-alt',
    //   path: '/convocationPlacesLinks',
    // },
    {
      menuKey: 'workflows',
      label: 'Parcours',
      icon: 'list-ul',
      path: '/workflows',
    },
    {
      menuKey: 'preOpInstructions',
      label: 'Consignes préop',
      icon: 'clipboard-list',
      path: '/preOpInstructions',
    },
    {
      menuKey: 'postOpInstructions',
      label: 'Consignes postop',
      icon: 'clipboard-list',
      path: '/postOpInstructions',
    },
    {
      menuKey: 'ambulisOperatingMethods',
      label: 'Protocoles',
      icon: 'clipboard-list',
      path: '/operatingMethods',
    },
    {
      menuKey: 'articles',
      label: 'Articles',
      icon: 'newspaper',
      path: '/articles',
    },
  ];

  // hotellerieMenuItems = [
  //   {
  //     menuKey: 'roomReservations',
  //     label: 'Réservations',
  //     icon: 'shopping-cart',
  //     path: '/roomReservations',
  //   },
  //   {
  //     menuKey: 'roomTypes',
  //     label: 'Types de chambre',
  //     icon: 'bed',
  //     path: '/roomTypes',
  //   },
  //   {
  //     menuKey: 'roomOptions',
  //     label: 'Options',
  //     icon: 'tasks',
  //     path: '/roomOptions',
  //   },
  // ];

  qoDMenuItems = [
    {
      menuKey: 'qod_dashboard',
      label: 'Dashboard',
      icon: 'clipboard',
      path: '/qod/',
    },
    {
      menuKey: 'qod_search',
      label: 'Recherche',
      icon: 'binoculars',
      path: '/qod/search',
    },
  ];

  constructor(props) {
    super(props);
    Authentication.loadFromStorage();
  }

  hasMultipleRightsCategories = () => {
    let nbRights = 0;

    if (Authentication.user.isEditorialAdmin) {
      nbRights++;
    }

    if (Authentication.user.isQoDAdmin) {
      nbRights++;
    }

    if (this.hasOneAdminRights()) {
      nbRights++;
    }

    return nbRights > 0;
  };

  hasOneAdminRights = () => {
    return Authentication.user.isUsersAdmin;
  };

  render() {
    if (!Authentication.isLogged() || !Authentication.clinic) {
      return <Redirect to={`/clinic/login`} />;
    }

    let sideMenuItems = [
      {
        menuKey: 'releaseNotes',
        label: 'Les nouveautés',
        icon: 'file',
        path: '/releaseNotes',
      },
    ];

    // Editorial
    if (Authentication.user.isEditorialAdmin) {
      if (this.hasMultipleRightsCategories()) {
        sideMenuItems.push({
          menuKey: 'editorial',
          label: 'Éditorial',
          subItems: this.editorialMenuItems,
        });
      } else {
        sideMenuItems = sideMenuItems.concat(this.editorialMenuItems);
      }
    }

    // Hôtellerie
    // if (Authentication.user.isRoomReservationsAdmin) {
    //   if (this.hasMultipleRightsCategories()) {
    //     sideMenuItems.push({
    //       menuKey: 'hotellerie',
    //       label: 'Hôtellerie',
    //       subItems: this.hotellerieMenuItems,
    //     });
    //   } else {
    //     sideMenuItems = sideMenuItems.concat(this.hotellerieMenuItems);
    //   }
    // }

    // QoD
    if (Authentication.user.isQoDAdmin) {
      if (this.hasMultipleRightsCategories()) {
        sideMenuItems.push({
          menuKey: 'qod',
          label: 'QoD',
          subItems: this.qoDMenuItems,
        });
      } else {
        sideMenuItems = sideMenuItems.concat(this.qoDMenuItems);
      }
    }

    // Admin
    if (this.hasOneAdminRights()) {
      let subItems = [];

      if (Authentication.user.isUsersAdmin) {
        subItems.push({
          menuKey: 'adminUsers',
          label: 'Utilisateurs',
          icon: 'users',
          path: '/admin/users',
        });
      }

      if (this.hasMultipleRightsCategories()) {
        sideMenuItems.push({
          menuKey: 'admin',
          label: 'Administration',
          subItems: subItems,
        });
      } else {
        sideMenuItems = sideMenuItems.concat(subItems);
      }
    }

    return (
      <PrivateLayout
        {...this.props}
        sideMenuTitle="ADEL"
        sideMenuSubitle="Back Office clinique"
        routesPrefix="clinic"
        unauthorizedRoutePath="login"
        authentication={Authentication}
        apiClient={ApiClient}
        sideMenuItems={sideMenuItems}
        sideMenuTopContent={<ClinicChoice />}
      />
    );
  }
}
